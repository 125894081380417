<template>


  <div class="flex m-0 h-screen">
    <div class="w-2/3 bg-[url('assets/images/bg.png')]  bg-left bg-cover flex items-center flex-col" @click="enter()">

      <div
          class="max-w-lg w-3/4	content-center my-auto bg-[url('assets/images/card-bottom.svg')] bg-bottom pb-6 pr-12 bg-no-repeat bg-left-bottom  bg-[length:90%] relative mr-20 ">


        <div
            class="bg-[url('assets/images/line-orange.svg')] h-full w-full bg-[length:40%_45%] bg-no-repeat absolute p-50 block z-0 bg-right-top"></div>

        <div class="bg-[#1D1D1B] p-10 text-white mt-10 text-center z-10 relative">
          <ul class="uppercase">
            <li class="mb-2">Hypermarché & alimentation</li>
            <li class="mb-2">Mode & Déco</li>
            <li class="mb-2">Beauté & Santé</li>
            <li class="mb-2">Culture & Loisirs</li>
            <li class="mb-2">Services</li>
          </ul>
          <ul>
            <li class="text-6xl font-['GeneralSans-Bold'] my-2">50 magasins</li>
            <li class="text-2xl font-['GeneralSans-Bold'] mb-2">à votre disposition</li>
          </ul>
          <section class=" max-w-screen-lg mx-auto ">
            <router-link :to="{name:'map'}"><img class="mx-auto  w-40 cursor-pointer animate-pulse"
                                                 src="@/assets/images/bt-start.svg" alt="screenshot"></router-link>

          </section>

        </div>


      </div>


    </div>
    <div class="w-1/3 h-full p-20 py-72">



          <div class="text-center"><img src="@/assets/images/logo.svg" alt=""></div>
          <div class="text-center ">
            <clock-component page="home"></clock-component>
            <p class="mt-4" @click="counter+=1">Ouvert du lundi au samedi<br/>
              <strong>De 9:30 à 20:00</strong>
            </p>


            <div v-if="counter>3" class="shadow-lg p-8">

              Choisir l'emplacement de l'écran :
              <select name="" id="" v-model="position" class="w-full">
                <option value="">(Sélectionner)</option>
                <option value="1">Etage</option>
                <option value="2">Entrée (Ouest)</option>
                <option value="3">Entrée (Sud)</option>
              </select>
              <button @click="configSave()"
                      class=" mt-5 w-full bg-orange-600 hover:bg-gray-200 hover:text-orange-500 text-white font-bold py-2 px-4 rounded-full"
                      v-if="position">Valider
              </button>
            </div>
          </div>
        </div>


  </div>


</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ClockComponent from "@/components/ClockComponent.vue";
import router from "@/router";

export default defineComponent({
  name: 'HomeView',
  components: {
    ClockComponent
  },
  data() {
    return {
      counter: 0,
      position: 0,
    }
  },
  mounted() {

    if (this.$route.params.id) {
     // console.log(this.$route.params.id);
      localStorage.position = this.$route.params.id;
      router.push({name: 'home'})
    }

    if (localStorage.position) {
      this.position = localStorage.position;
    }
  },
  methods: {

    configSave() {
      this.counter = 0;
      localStorage.position = this.position;
    },
    enter() {
      router.push({name: 'map'})
    }
  }

});
</script>

<style>

</style>
