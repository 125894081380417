<template>
  <div :class="page">
    <p class=" clock font-['GeneralSans-Bold'] leading-none"  >{{ time }}</p>
    <p class=" date font-['GeneralSans-Bold']">{{ date }}</p>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'ClockComponent',
  data() {
    return {
      time: '',
      date: '',
      week: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
      months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
    }

  },

  props: {
    page: String,
  },
  mounted() {
    setInterval(this.updateTime, 1000);

  },
  methods: {


    updateTime() {

      let now = new Date();
      this.time = this.zeroPadding(now.getHours(), 2) + ':' + this.zeroPadding(now.getMinutes(), 2);
      this.date = this.week[now.getDay()] + ' ' + now.getDate() + ' ' + this.months[now.getMonth()] + ' ' + now.getFullYear()
    },
    zeroPadding(num: number, digit: number) {
      var zero = '';
      for (var i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    }


  }
});
</script>

<style scoped lang="scss">

  .home .clock{
    font-size: 7em;
  }

  .home .date{
    font-size: 1em;
  }


  .map .clock{
    font-size: 4em;
  }

  .map .date{
    font-size:1em;
  }
</style>
