import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/bt-start.svg'
import _imports_1 from '@/assets/images/logo.svg'


const _hoisted_1 = { class: "flex m-0 h-screen" }
const _hoisted_2 = { class: "max-w-lg w-3/4 content-center my-auto bg-[url('assets/images/card-bottom.svg')] bg-bottom pb-6 pr-12 bg-no-repeat bg-left-bottom bg-[length:90%] relative mr-20" }
const _hoisted_3 = { class: "bg-[#1D1D1B] p-10 text-white mt-10 text-center z-10 relative" }
const _hoisted_4 = { class: "max-w-screen-lg mx-auto" }
const _hoisted_5 = { class: "w-1/3 h-full p-20 py-72" }
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = {
  key: 0,
  class: "shadow-lg p-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_clock_component = _resolveComponent("clock-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "w-2/3 bg-[url('assets/images/bg.png')] bg-left bg-cover flex items-center flex-col",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.enter()))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "bg-[url('assets/images/line-orange.svg')] h-full w-full bg-[length:40%_45%] bg-no-repeat absolute p-50 block z-0 bg-right-top" }, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createStaticVNode("<ul class=\"uppercase\"><li class=\"mb-2\">Hypermarché &amp; alimentation</li><li class=\"mb-2\">Mode &amp; Déco</li><li class=\"mb-2\">Beauté &amp; Santé</li><li class=\"mb-2\">Culture &amp; Loisirs</li><li class=\"mb-2\">Services</li></ul><ul><li class=\"text-6xl font-[&#39;GeneralSans-Bold&#39;] my-2\">50 magasins</li><li class=\"text-2xl font-[&#39;GeneralSans-Bold&#39;] mb-2\">à votre disposition</li></ul>", 2)),
          _createElementVNode("section", _hoisted_4, [
            _createVNode(_component_router_link, { to: {name:'map'} }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("img", {
                  class: "mx-auto w-40 cursor-pointer animate-pulse",
                  src: _imports_0,
                  alt: "screenshot"
                }, null, -1)
              ])),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-center" }, [
        _createElementVNode("img", {
          src: _imports_1,
          alt: ""
        })
      ], -1)),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_clock_component, { page: "home" }),
        _createElementVNode("p", {
          class: "mt-4",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.counter+=1))
        }, _cache[7] || (_cache[7] = [
          _createTextVNode("Ouvert du lundi au samedi"),
          _createElementVNode("br", null, null, -1),
          _createElementVNode("strong", null, "De 9:30 à 20:00", -1)
        ])),
        (_ctx.counter>3)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _cache[9] || (_cache[9] = _createTextVNode(" Choisir l'emplacement de l'écran : ")),
              _withDirectives(_createElementVNode("select", {
                name: "",
                id: "",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.position) = $event)),
                class: "w-full"
              }, _cache[8] || (_cache[8] = [
                _createElementVNode("option", { value: "" }, "(Sélectionner)", -1),
                _createElementVNode("option", { value: "1" }, "Etage", -1),
                _createElementVNode("option", { value: "2" }, "Entrée (Ouest)", -1),
                _createElementVNode("option", { value: "3" }, "Entrée (Sud)", -1)
              ]), 512), [
                [_vModelSelect, _ctx.position]
              ]),
              (_ctx.position)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.configSave())),
                    class: "mt-5 w-full bg-orange-600 hover:bg-gray-200 hover:text-orange-500 text-white font-bold py-2 px-4 rounded-full"
                  }, "Valider "))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}